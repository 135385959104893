import { FeatureData } from '@innedit/innedit';
import { useSelector } from 'dataformjs';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import params from '../../../core/sysext/Boutique/containers/Produit/containers/Livraison/params.json';
import CMSView from '../../../core/sysext/CMS/components/View';
import Backoffice from '../../../core/sysext/CMS/containers/Backoffice';
import Form from '../../../core/sysext/CMS/containers/Boutique/components/new/Form';

const PageLivraisonsCreate: FC<PageProps> = props => {
  const { location } = props;

  const boutique = useSelector((state: any) =>
    state.cms.boutiques
      ? state.cms.boutiques[state.cms.indexBoutique || 0]
      : undefined,
  );

  const model = new FeatureData(boutique, {
    params,
    collectionName: 'livraisons',
  });

  return (
    <Backoffice location={location}>
      <CMSView>
        <Form
          {...props}
          itemPathnamePrefix="/boutique/livraisons/"
          model={model}
          name="livraison"
          type="create"
        />
      </CMSView>
    </Backoffice>
  );
};

export default PageLivraisonsCreate;
